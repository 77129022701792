.jumbotron {
    background-color: #73b73e;
    text-align: initial !important;
    height: 90px;
}

.lead {
    font-size: xx-large;
}

p, h1 {
    margin: 0;
}